export const ucatastrophsAttributesMapping = {
  active: 'Active',
  id: 'ID',
  name: 'Name',
  description: "Description",
  dangerlevel0: "Dangerlevel 0",
  dangerlevel1: "Dangerlevel 1",
  dangerlevel2: "Dangerlevel 2",
  dangerlevel3: "Dangerlevel 3",
  keep: "Keep",
  img: "Img",
  version: "Version"
};

export interface UCatastrophs {
  active: string;
  id: string;
  name: string;
  description: string;
  dangerlevel0: string;
  dangerlevel1: string;
  dangerlevel2: string;
  dangerlevel3: string;
  keep: string;
  img: string;
  version: string;
}