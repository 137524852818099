export const perkAttributesMapping = {
    id: 'ID',
    name: 'Name',
    description: "Description",
    img: "Img"
  };

export interface Perk {
    id: string;
    name: string;
    description: string;
    img: string;
  }