<div *ngIf="planets$ | async as planets">
    <div *ngFor="let planet of planets">
        <div *ngIf="planet.version >= version && planet.active == 'yes'" class="planet-card">
            <div class="planet-header">
                <span class="planet-dangerlevel">{{planet.dangerlevel}}</span><span
                    class="planet-name">{{planet.name}}</span>
            </div>
            <div class="planet-img" *ngIf="planet.img; else indexImg">
                <img src={{planet.img}}>
            </div>
            <ng-template #indexImg>
                <div class="planet-img planet-round">
                    <img src="/assets/planets/{{planet.id}}.jpg">
                </div>
            </ng-template>
            <div class="planet-footer">
                <div class="planet-perks" *ngIf="perks">
                    <div *ngFor="let perk of planet.perks" class="planet-perk">
                        <img src="{{ perks[perk - 1].img }}">
                    </div>
                </div>
                <div class="planet-description">
                    {{planet.description}}
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="actions$ | async as actions">
    <div *ngFor="let action of actions">
        <div *ngIf="action.version >= version && action.active == 'yes'" class="action-card">
            <div class="action-img">
                <img src={{action.img}}>
            </div>
            <div class="action-name">
                {{action.name}}
            </div>
            <div class="action-description">
                {{action.description}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="catastrophs$ | async as catastrophs">
    <div *ngFor="let catastroph of catastrophs">
        <div *ngIf="catastroph.version >= version && catastroph.active == 'yes'" class="catastroph-card">
            <!--style="background-image:url({{catastroph.img}})"-->
            <div class="catastroph-name">
                {{catastroph.name}}
            </div>
            <div class="catastroph-dangerlevel zero">
                <div>
                    -0-<br>
                    {{catastroph.dangerlevel0}}
                </div>
            </div>
            <div class="catastroph-dangerlevel one">
                <div>
                    -1-<br>
                    {{catastroph.dangerlevel1}}
                </div>
            </div>
            <div class="catastroph-dangerlevel two">
                <div>
                    -2-<br>
                    {{catastroph.dangerlevel2}}
                </div>
            </div>
            <div class="catastroph-dangerlevel three">
                <div>
                    -3-<br>
                    {{catastroph.dangerlevel3}}
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="ucatastrophs$ | async as ucatastrophs">
    <div *ngFor="let ucatastroph of ucatastrophs">
        <div *ngIf="ucatastroph.version >= version && ucatastroph.active == 'yes'" class="ucatastroph-card">
            <div class="catastroph-name">
                {{ucatastroph.name}}
            </div>
            <div class="catastroph-dangerlevel">
                {{ucatastroph.dangerlevel0}}
            </div>
        </div>
    </div>
</div>