import { Component, OnInit } from '@angular/core';
import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { Observable } from 'rxjs';

import { Version } from '../globals';
import { Settings, settingsAttributesMapping } from "../setting.model";

import { Planet, planetAttributesMapping } from "../planet.model";
import { Perk, perkAttributesMapping } from "../perk.model";
import { Action, actionAttributesMapping } from "../action.model";
import { Catastrophs, catastrophsAttributesMapping } from "../catastrophs.model";
import { UCatastrophs, ucatastrophsAttributesMapping } from "../ucatastrophs.model";

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'],
})
export class CardsComponent implements OnInit {
  settings$: Observable<Settings[]>;
  settings: Settings[];
  version: string = Version;
  planets$: Observable<Planet[]>;
  perks$: Observable<Perk[]>;
  perks: Perk[];
  actions$: Observable<Action[]>
  catastrophs$: Observable<Catastrophs[]>
  ucatastrophs$: Observable<UCatastrophs[]>

  constructor(private googleSheetsDbService: GoogleSheetsDbService) { }

  ngOnInit(): void {
    this.settings$ = this.googleSheetsDbService.get<Settings>('1pFjwn4Hf9MLikbfxWRtUDkGzsGcmK_MmkXukyImjYrs', "Settings (6)", settingsAttributesMapping);
    this.settings$.subscribe(x => {
       this.version = x[0].version;
       console.log("Current Version: " + x[0].version); 
       console.log("Max Version: " + x[0].mversion);
       console.log("Version can be changed in Google Sheets -> Settings tab."); 
      });

    this.planets$ = this.googleSheetsDbService.get<Planet>('1pFjwn4Hf9MLikbfxWRtUDkGzsGcmK_MmkXukyImjYrs', "Planets (1)", planetAttributesMapping);
    this.actions$ = this.googleSheetsDbService.get<Action>('1pFjwn4Hf9MLikbfxWRtUDkGzsGcmK_MmkXukyImjYrs', "Cards (2)", actionAttributesMapping);
    this.catastrophs$ = this.googleSheetsDbService.get<Catastrophs>('1pFjwn4Hf9MLikbfxWRtUDkGzsGcmK_MmkXukyImjYrs', "Catastrophs (3)", catastrophsAttributesMapping);
    this.ucatastrophs$ = this.googleSheetsDbService.get<UCatastrophs>('1pFjwn4Hf9MLikbfxWRtUDkGzsGcmK_MmkXukyImjYrs', "UCatastrophs (4)", ucatastrophsAttributesMapping);
    this.perks$ = this.googleSheetsDbService.get<Perk>('1pFjwn4Hf9MLikbfxWRtUDkGzsGcmK_MmkXukyImjYrs', "Perks (5)", perkAttributesMapping);
    this.perks$.subscribe(x => this.perks = x)
  }

}
