export const actionAttributesMapping = {
    active: 'Active',
    id: 'ID',
    name: 'Name',
    description: "Description",
    img: "Img",
    version: "Version"
  };

export interface Action {
    active: string;
    id: string;
    name: string;
    description: string;
    img: string;
    version: string;
  }