import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { API_KEY, GoogleSheetsDbService } from 'ng-google-sheets-db';

import { AppComponent } from './app.component';
import { CardsComponent } from './cards/cards.component';

@NgModule({
  declarations: [
    AppComponent,
    CardsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: API_KEY,
      useValue: "AIzaSyCL8kMcXsrQUDA7LiwAoYkytlr0Uu68PIo",
    },
    [GoogleSheetsDbService]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
