export const planetAttributesMapping = {
    active: "Active",
    id: 'ID',
    name: 'Name',
    description: "Description",
    dangerlevel: "Dangerlevel",
    alignment: "Alignment",
    perks: {
      _prefix: 'Perk ',
      _listField: true
    },
    img: "Img",
    version: "Version"
  };

export interface Planet {
    active: string;
    id: string;
    name: string;
    description: string;
    dangerlevel: string;
    alignment: string;
    perks: string[];
    img: string;
    version: string;
  }